// Modules
import React from 'react';
import {List, Edit, Create, SimpleForm, required} from 'react-admin';
import {
    TextField,
    Datagrid,
    TextInput,
    BooleanInput,
    LongTextInput,
    AutocompleteInput,
    ChipField,
    RadioButtonGroupInput,
    NumberInput,
    SelectInput
} from 'react-admin';
// Constants
import {NodeResolver, AndOrResolver, DeliverySchema, CurrencyResolver} from '../../constants';
import { Pagination } from '../../components';

const nodesSource = ['nodes_1', 'nodes_2', 'nodes_3', 'nodes_4', 'nodes_5', 'nodes_6', 'nodes_7', 'nodes_8', 'nodes_9', 'nodes_10'];
export const TargetingList = (props) => (
    <List {...props} pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <ChipField source="andOr" />
            <TextFieldforList source={nodesSource} label="Nodes" sortable={false}/>
        </Datagrid>
    </List>
);

export const TargetingCreate = (props) =>(
    <Create {...props}>
        <TargetingSimpleForm redirect="list"/>
    </Create>
);

export const TargetingEdit = (props) => (
    <Edit {...props}>
        <TargetingSimpleForm redirect="list"/>
    </Edit>
);

const TargetingSimpleForm = (props) =>(
    <SimpleForm {...props}>

        <TextInput source="name" validate={[required()]} />

        <SelectInput
            defaultValue="None"
            source="predictiveNode"
            label="Predictive Targeting"
            optionValue="value"
            optionText="label"
            choices={[
                { _id: 0, value: 'None', label: 'None' },
                { _id: 1, value: 'Top_VTR', label: 'Top VTR, %' },
                { _id: 2, value: 'Top_CTR', label: 'Top CTR, %' },
                { _id: 3, value: 'Top_FR', label: 'Top Fill Rate, %' },
                { _id: 4, value: 'Top_Banner_VTR', label: 'Top Banner VTR, %' },
            ]}
        />

        <NumberInput source="predictiveValue" label="Predictor Value" />

        <RadioButtonGroupInput source="andOr" choices={[
            {
                id: AndOrResolver.AND,
                name: AndOrResolver.AND,
            },
            {
                id: AndOrResolver.OR,
                name: AndOrResolver.OR,
            },
        ]} defaultValue={AndOrResolver.AND}/>
        <Subtargeting id='1' />
        <Subtargeting id='2' />
        <Subtargeting id='3' />
        <Subtargeting id='4' />
        <Subtargeting id='5' />
        <Subtargeting id='6' />
        <Subtargeting id='7' />
        <Subtargeting id='8' />
        <Subtargeting id='9' />
        <Subtargeting id='10' />
    </SimpleForm>
);

function nodeChoice() {
    const nodeChoices = [];
    let idx = 0;
    for (let key in NodeResolver) {
        nodeChoices.push({
            _id: idx,
            optionKey: key,
            optionValue: NodeResolver[key].replace(/\./g, ' > '),
        });
        idx++;
    }
    return nodeChoices;
}

const Subtargeting = ({id}) => {
    return (
        <>
            <AutocompleteInput
                source={`nodes_${id}`}
                label="Node"
                choices={nodeChoice()}
                optionText="optionValue"
                optionValue="optionKey"
                inputStyle={{width: '100%'}}
                options={{
                    fullWidth: true,
                }}
            />
            <BooleanInput source={`mustExist_${id}`} label="Node must exist" />
            <BooleanInput source={`useContains_${id}`} label="Use Contains" defaultValue={false}/>
            <BooleanInput source={`negative_${id}`} label="Negative" defaultValue={false}/>
            <LongTextInput source={`list_${id}`} label="List" />
        </>
    )
}

const TextFieldforList = ({ source, record = {} }) => {
    const sourceParce =  source && source.reduce( (acc, sourceItem) => {
        const value = record[sourceItem];
        if (value) {
            acc.push(value.replace(/([a-z])([A-Z])/g, function(match, fix1, fix2){
                return `${fix1} > ${fix2}`;
            }))
        }
        return acc;
    }, []);
    return sourceParce.join(', ');
}
