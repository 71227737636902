// Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, Edit, Create, SimpleForm, Filter, required } from 'react-admin';
import {
    TextField,
    ReferenceField,
    NumberField,
    ShowButton,
    Datagrid,
    ChipField,
    UrlField,
    TextInput,
    ReferenceInput,
    CheckboxGroupInput,
    ReferenceArrayInput,
    RadioButtonGroupInput,
    NumberInput,
    BooleanInput,
    SelectArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
} from 'react-admin';
// Constants
import { publisher, supply, mimeType } from '../../App';
import { StatusResolver } from '../../constants';
import { Pagination } from '../../components';

const styles = {
    root: {
        width: '200px!important'
    }
};

export const VideoList = props => (
    <List {...props} filters={<VideoFilters />} title="Video Placements" pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <ChipField source="status" />
            <NumberField source="cpm" label="CPM" />
            <ReferenceField source="publisher.id" reference={publisher} label="Publisher" sortable={false}>
                <ChipField source="name" />
            </ReferenceField>
            <ReferenceField source="site.id" reference={supply} label="Supply (site)" linkType={false} sortable={false}>
                <UrlField source="defaultURL" />
            </ReferenceField>
            <ShowButton />
        </Datagrid>
    </List>
);

export const VideoEdit = (props) => (
    <Edit {...props} title="Edit Video placement">
        <VideoSimpleForm />
    </Edit>
);

export const VideoCreate = (props) => (
    <Create {...props} title="Create Video placement">
        <VideoSimpleForm />
    </Create>
);

const VideoSimpleForm = props => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
        <ReferenceInput
            label="Publisher"
            source="publisher.id"
            reference={publisher}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            label="Site"
            source="site.id"
            reference={supply}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <RadioButtonGroupInput
            defaultValue={StatusResolver.Active}
            source="status"
            optionValue="value"
            optionText="value"
            choices={[
                { _id: 0, value: StatusResolver.Active },
                { _id: 1, value: StatusResolver.Disabled },
                { _id: 2, value: StatusResolver.Test },
            ]}
        />
        <TextInput source="passbackURL" label="Passback URL" fullWidth/>
        <TextInput source="longId" label="Long id" />
        <NumberInput source="cpm" step={1} label="CPM" />
        {/* <RadioButtonGroupInput
            source="perAction"
            optionValue="value"
            optionText="value"
            choices={[
                { _id: 0, value: PerActionResolver.CPM },
                { _id: 1, value: PerActionResolver.Revshare },
            ]}
        /> */}
        <NumberInput source="ssp_cpm" step={1} label="SSP CPM" />
        <NumberInput source="revshare" step={1} label="Revshare" />
        <BooleanInput
            label="VAST Audio"
            source="VASTAudio"
        />
        <NumberInput source="maxDuration" step={1} label="Max Duration" />
        <ReferenceArrayInput
            allowEmpty={true}
            label="Mime-types"
            source="mimeTypesIds"
            reference={mimeType}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={ searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
    </SimpleForm>
);

const VideoFilters = withStyles(styles)(({classes, ...rest}) => (
    <Filter {...rest}>
        <CheckboxGroupInput
            alwaysOn={true}
            source="status_in"
            choices={[
                { _id: 0, value: StatusResolver.Active },
                { _id: 1, value: StatusResolver.Disabled },
                { _id: 2, value: StatusResolver.Test },
            ]}
            optionText="value"
            optionValue="value"
            label={null}
        />
        <ReferenceArrayInput
            label="Publisher"
            source="publisher.id_in"
            reference={publisher}
            allowEmpty={true}
            alwaysOn={true}
            className={classes.root}
        >
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            label="Site"
            source="site.id_in"
            reference={supply}
            allowEmpty={true}
            alwaysOn={true}
            className={classes.root}
        >
            <SelectArrayInput optionText="defaultURL" />
        </ReferenceArrayInput>
    </Filter>
));
/*
mimeTypes: [MimeType!]
perAction: PerAction
*/