// Modules
import React from 'react';

export function LinkField({ source, record, prefix, postfix }) {
    const children = record[source];
    const href = `${prefix}${children}${postfix}`;
    return (
        <a href={href}>{children}</a>
    );
}

LinkField.displayName = 'LinkField';

LinkField.defaultProps = {
    addLabel: true,
    prefix: '',
    postfix: '',
};
