export const NodeResolver = {
    True: 'True',
    False: 'False',
    Throttle: 'Throttle',
    SyncedDSPs: 'SyncedDSPs',
    RevealedDomains : 'RevealedDomains',
    PrivateTraffic: 'PrivateTraffic',
    TestTraffic: 'TestTraffic',
    BidAdID: 'Bid.AdID',
    BidAdvDomain: 'Bid.AdvDomain',
    BidCampaignID: 'Bid.CampaignID',
    BidCreativeID: 'Bid.CreativeID',
    BidCat: 'Bid.Cat',
    RequestSiteID: 'Request.Site.ID',
    RequestSiteName: 'Request.Site.Name',
    RequestSiteDomain: 'Request.Site.Domain',
    RequestSiteCat: 'Request.Site.Cat',
    RequestSiteSectionCat: 'Request.Site.Section.Cat',
    RequestSitePageCat: 'Request.Site.Page.Cat',
    RequestSitePublisherID: 'Request.Site.Publisher.ID',
    RequestSitePublisherName: 'Request.Site.Publisher.Name',
    RequestSitePublisherCat: 'Request.Site.Publisher.Cat',
    RequestSitePublisherDomain: 'Request.Site.Publisher.Domain',
    RequestSiteContentID: 'Request.Site.Content.ID',
    RequestSiteContentTitle: 'Request.Site.Content.Title',
    RequestSiteContentSeries: 'Request.Site.Content.Series',
    RequestSiteContentSeason: 'Request.Site.Content.Season',
    RequestSiteContentArtist: 'Request.Site.Content.Artist',
    RequestSiteContentGenre: 'Request.Site.Content.Genre',
    RequestSiteContentAlbum: 'Request.Site.Content.Album',
    RequestSiteContentISRC: 'Request.Site.Content.ISRC',
    RequestSiteContentProducerID: 'Request.Site.Content.Producer.ID',
    RequestSiteContentProducerName: 'Request.Site.Content.Producer.Name',
    RequestSiteContentProducerCat: 'Request.Site.Content.Producer.Cat',
    RequestSiteContentProducerDomain: 'Request.Site.Content.Producer.Domain',
    RequestSiteContentURL: 'Request.Site.Content.URL',
    RequestSiteContentCat: 'Request.Site.Content.Cat',
    RequestSiteContentContentRating: 'Request.Site.Content.Content.Rating',
    RequestSiteContentUserRating: 'Request.Site.Content.User.Rating',
    RequestSiteContentKeywords: 'RequestSite.Content.Keywords',
    RequestSiteContentLanguage: 'Request.Site.Content.Language',
    RequestSiteKeywords: 'Request.Site.Keywords',
    RequestSitePage: 'Request.Site.Page',
    RequestSiteRef: 'Request.Site.Ref',
    RequestSiteSearch: 'Request.Site.Search',
    RequestAppID: 'Request.App.ID',
    RequestAppName: 'Request.App.Name',
    RequestAppDomain: 'Request.App.Domain',
    RequestAppCat: 'Request.App.Cat',
    RequestAppSectionCat: 'Request.App.Section.Cat',
    RequestAppPageCat: 'Request.App.Page.Cat',
    RequestAppPublisherID: 'Request.App.Publisher.ID',
    RequestAppPublisherName: 'Request.App.Publisher.Name',
    RequestAppPublisherCat: 'Request.App.Publisher.Cat',
    RequestAppPublisherDomain: 'Request.App.Publisher.Domain',
    RequestAppContentID: 'Request.App.Content.ID',
    RequestAppContentTitle: 'Request.App.Content.Title',
    RequestAppContentSeries: 'Request.App.Content.Series',
    RequestAppContentSeason: 'Request.App.Content.Season',
    RequestAppContentArtist: 'Request.App.Content.Artist',
    RequestAppContentGenre: 'Request.App.Content.Genre',
    RequestAppContentAlbum: 'Request.App.Content.Album',
    RequestAppContentISRC: 'Request.App.Content.ISRC',
    RequestAppContentProducerID: 'Request.App.Content.Producer.ID',
    RequestAppContentProducerName: 'Request.App.Content.Producer.Name',
    RequestAppContentProducerCat: 'Request.App.Content.Producer.Cat',
    RequestAppContentProducerDomain: 'Request.App.Content.Producer.Domain',
    RequestAppContentURL: 'Request.App.Content.URL',
    RequestAppContentCat: 'Request.App.Content.Cat',
    RequestAppContentContentRating: 'Request.App.Content.ContentRating',
    RequestAppContentUserRating: 'Request.App.Content.UserRating',
    RequestAppContentKeywords: 'Request.App.Content.Keywords',
    RequestAppContentLanguage: 'Request.App.Content.Language',
    RequestAppKeywords: 'Request.App.Keywords',
    RequestAppBundle: 'Request.App.Bundle',
    RequestAppStoreURL: 'Request.App.StoreURL',
    RequestAppVer: 'Request.App.Ver',
    RequestDeviceUA: 'Request.Device.UA',
    RequestDeviceGeoCountry: 'Request.Device.Geo.Country',
    RequestDeviceGeoRegion: 'Request.Device.Geo.Region',
    RequestDeviceGeoRegionFIPS104: 'Request.Device.Geo.RegionFIPS104',
    RequestDeviceGeoMetro: 'Request.Device.Geo.Metro',
    RequestDeviceGeoCity: 'Request.Device.Geo.City',
    RequestDeviceGeoZip: 'Request.Device.Geo.Zip',
    RequestDeviceIP: 'Request.Device.IP',
    RequestDeviceIPv6: 'Request.Device.IPv6',
    RequestDeviceMake: 'Request.Device.Make',
    RequestDeviceModel: 'Request.Device.Model',
    RequestDeviceOS: 'Request.Device.OS',
    RequestDeviceOSVer: 'Request.Device.OSVer',
    RequestDeviceHwVer: 'Request.Device.HwVer',
    RequestDeviceFlashVer: 'Request.Device.FlashVer',
    RequestDeviceLanguage: 'Request.Device.Language',
    RequestDeviceCarrier: 'Request.Device.Carrier',
    RequestDeviceMCCMNC: 'Request.Device.MCCMNC',
    RequestDeviceIFA: 'Request.Device.IFA',
    RequestDeviceIDSHA1: 'Request.Device.IDSHA1',
    RequestDeviceIDMD5: 'Request.Device.IDMD5',
    RequestDevicePIDSHA1: 'Request.Device.PIDSHA1',
    RequestDevicePIDMD5: 'Request.Device.PIDMD5',
    RequestDeviceMacSHA1: 'Request.Device.MacSHA1',
    RequestDeviceMacMD5: 'Request.Device.MacMD5',
    RequestUserID: 'Request.User.ID',
    RequestUserBuyerID: 'Request.User.BuyerID',
    RequestUserBuyerUID: 'Request.User.BuyerUID',
    RequestUserGender: 'Request.User.Gender',
    RequestUserKeywords: 'Request.User.Keywords',
    RequestUserCustomData: 'Request.User.CustomData',
    RequestUserGeoCountry: 'Request.User.Geo.Country',
    RequestUserGeoRegion: 'Request.User.Geo.Region',
    RequestUserGeoRegionFIPS104: 'Request.User.Geo.RegionFIPS104',
    RequestUserGeoMetro: 'Request.User.Geo.Metro',
    RequestUserGeoCity: 'Request.User.Geo.City',
    RequestUserGeoZip: 'Request.User.Geo.Zip',
    RequestWSeat: 'Request.WSeat',
    RequestBSeat: 'Request.BSeat',
    RequestWLang: 'Request.WLang',
    RequestCur: 'Request.Cur',
    RequestBcat: 'Request.Bcat',
    RequestBAdv: 'Request.BAdv',
    RequestBApp: 'Request.BApp',
    RequestSourceTransactionID: 'Request.Source.TransactionID',
    RequestSourcePaymentChain: 'Request.Source.PaymentChain',
    ImpressionID: 'Impression.ID',
    ImpressionBannerID: 'Impression.Banner.ID',
    ImpressionBannerMimes: 'Impression.Banner.Mimes',
    ImpressionVideoMimes: 'Impression.Video.Mimes',
    ImpressionAudioMimes: 'Impression.Audio.Mimes',
    ImpressionNativeVer: 'Impression.Native.Ver',
    ImpressionDisplayManager: 'Impression.DisplayManager',
    ImpressionDisplayManagerVer: 'Impression.DisplayManagerVer',
    ImpressionTagID: 'Impression.TagID',
    ImpressionBidFloorCurrency: 'Impression.BidFloorCurrency',
    ImpressionIFrameBuster: 'Impression.IFrameBuster',
    Network: 'Network',
    RequestSiteContentEpisode: 'Request.Site.Content.Episode',
    RequestSiteContentProdQuality: 'Request.Site.Content.ProdQuality',
    RequestSiteContentVideoQuality: 'Request.Site.Content.VideoQuality',
    RequestSiteContentContext: 'Request.Site.Content.Context',
    RequestSiteContentQAGMediaRating: 'Request.Site.Content.QAGMediaRating',
    RequestSiteContentLiveStream: 'Request.Site.Content.LiveStream',
    RequestSiteContentSourceRelationship: 'Request.Site.Content.SourceRelationship',
    RequestSiteContentLen: 'Request.Site.Content.Len',
    RequestSiteContentEmbeddable: 'Request.Site.Content.Embeddable',
    RequestSiteMobile: 'Request.Site.Mobile',
    RequestAppContentEpisode: 'Request.App.Content.Episode',
    RequestAppContentProdQuality: 'Request.App.Content.ProdQuality',
    RequestAppContentVideoQuality: 'Request.App.Content.VideoQuality',
    RequestAppContentContext: 'Request.App.Content.Context',
    RequestAppContentQAGMediaRating: 'Request.App.Content.QAGMediaRating',
    RequestAppContentLiveStream: 'Request.App.Content.LiveStream',
    RequestAppContentSourceRelationship: 'Request.App.Content.SourceRelationship',
    RequestAppContentLen: 'Request.App.Content.Len',
    RequestAppContentEmbeddable: 'Request.App.Content.Embeddable',
    RequestAppPaid: 'Request.App.Paid',
    RequestDeviceGeoType: 'Request.Device.Geo.Type',
    RequestDeviceGeoAccuracy: 'Request.Device.Geo.Accuracy',
    RequestDeviceGeoLastFix: 'Request.Device.Geo.LastFix',
    RequestDeviceGeoIPService: 'Request.Device.Geo.IPService',
    RequestDeviceGeoUTCOffset: 'Request.Device.Geo.UTCOffset',
    RequestDeviceDNT: 'Request.Device.DNT',
    RequestDeviceLMT: 'Request.Device.LMT',
    RequestDeviceDeviceType: 'Request.Device.DeviceType',
    RequestDeviceH: 'Request.Device.H',
    RequestDeviceW: 'Request.Device.W',
    RequestDevicePPI: 'Request.Device.PPI',
    RequestDeviceJS: 'Request.Device.JS',
    RequestDeviceGeoFetch: 'Request.Device.GeoFetch',
    RequestDeviceConnType: 'Request.Device.ConnType',
    RequestUserYOB: 'Request.User.YOB',
    RequestUserGeoType: 'Request.User.Geo.Type',
    RequestUserGeoAccuracy: 'Request.User.Geo.Accuracy',
    RequestUserGeoLastFix: 'Request.User.Geo.LastFix',
    RequestUserGeoIPService: 'Request.User.Geo.IPService',
    RequestUserGeoUTCOffset: 'Request.User.Geo.UTCOffset',
    RequestTest: 'Request.Test',
    RequestAuctionType: 'Request.AuctionType',
    RequestTMax: 'Request.TMax',
    RequestAllImps: 'Request.AllImps',
    RequestSourceFinalSaleDecision: 'Request.Source.FinalSaleDecision',
    RequestRegsCoppa: 'Request.Regs.Coppa',
    RequestPmpPrivate: 'Request.Pmp.Private',
    ImpressionBannerW: 'Impression.Banner.W',
    ImpressionBannerH: 'Impression.Banner.H',
    ImpressionBannerWMax: 'Impression.Banner.WMax',
    ImpressionBannerHMax: 'Impression.Banner.HMax',
    ImpressionBannerWMin: 'Impression.Banner.WMin',
    ImpressionBannerHMin: 'Impression.Banner.HMin',
    ImpressionBannerBType: 'Impression.Banner.BType',
    ImpressionBannerBAttr: 'Impression.Banner.BAttr',
    ImpressionBannerPos: 'Impression.Banner.Pos',
    ImpressionBannerTopFrame: 'Impression.Banner.TopFrame',
    ImpressionBannerExpDir: 'Impression.Banner.ExpDir',
    ImpressionBannerApi: 'Impression.Banner.Api',
    ImpressionVideoMinDuration: 'Impression.Video.MinDuration',
    ImpressionVideoMaxDuration: 'Impression.Video.MaxDuration',
    ImpressionVideoProtocols: 'Impression.Video.Protocols',
    ImpressionVideoProtocol: 'Impression.Video.Protocol',
    ImpressionVideoW: 'Impression.Video.W',
    ImpressionVideoH: 'Impression.Video.H',
    ImpressionVideoStartDelay: 'Impression.Video.StartDelay',
    ImpressionVideoLinearity: 'Impression.Video.Linearity',
    ImpressionVideoSkip: 'Impression.Video.Skip',
    ImpressionVideoSkipMin: 'Impression.Video.SkipMin',
    ImpressionVideoSkipAfter: 'Impression.Video.SkipAfter',
    ImpressionVideoSequence: 'Impression.Video.Sequence',
    ImpressionVideoBAttr: 'Impression.Video.BAttr',
    ImpressionVideoMaxExtended: 'Impression.Video.MaxExtended',
    ImpressionVideoMinBitrate: 'Impression.Video.MinBitrate',
    ImpressionVideoMaxBitrate: 'Impression.Video.MaxBitrate',
    ImpressionVideoPlaybackMethod: 'Impression.Video.PlaybackMethod',
    ImpressionVideoDelivery: 'Impression.Video.Delivery',
    ImpressionVideoPos: 'Impression.Video.Pos',
    ImpressionVideoApi: 'Impression.Video.Api',
    ImpressionVideoCompanionType: 'Impression.Video.CompanionType',
    ImpressionVideoPlacement: 'Impression.Video.Placement',
    ImpressionVideoVideoType: 'Impression.Video.VideoType',
    ImpressionAudioMinDuration: 'Impression.Audio.MinDuration',
    ImpressionAudioMaxDuration: 'Impression.Audio.MaxDuration',
    ImpressionAudioProtocols: 'Impression.Audio.Protocols',
    ImpressionAudioStartDelay: 'Impression.Audio.StartDelay',
    ImpressionAudioSequence: 'Impression.Audio.Sequence',
    ImpressionAudioBAttr: 'Impression.Audio.BAttr',
    ImpressionAudioMaxExtended: 'Impression.Audio.MaxExtended',
    ImpressionAudioMinBitrate: 'Impression.Audio.MinBitrate',
    ImpressionAudioMaxBitrate: 'Impression.Audio.MaxBitrate',
    ImpressionAudioDelivery: 'Impression.Audio.Delivery',
    ImpressionAudioAPI: 'Impression.Audio.API',
    ImpressionAudioCompanionType: 'Impression.Audio.CompanionType',
    ImpressionAudioMaxSequence: 'Impression.Audio.MaxSequence',
    ImpressionAudioFeed: 'Impression.Audio.Feed',
    ImpressionAudioStitched: 'Impression.Audio.Stitched',
    ImpressionAudioNVol: 'Impression.Audio.NVol',
    ImpressionNativeAPI: 'Impression.Native.API',
    ImpressionNativeBAttr: 'Impression.Native.BAttr',
    ImpressionPmpPrivate: 'Impression.Pmp.Private',
    ImpressionInstl: 'Impression.Instl',
    ImpressionSecure: 'Impression.Secure',
    ImpressionExp: 'Impression.Exp',
    Request: 'Request',
    RequestSite: 'Request.Site',
    RequestSitePrivacyPolicy: 'Request.Site.PrivacyPolicy',
    RequestSitePublisher: 'Request.Site.Publisher',
    RequestSiteContent: 'Request.Site.Content',
    RequestSiteContentProducer: 'Request.Site.Content.Producer',
    RequestApp: 'Request.App',
    RequestAppPrivacyPolicy: 'Request.App.PrivacyPolicy',
    RequestAppPublisher: 'Request.App.Publisher',
    RequestAppContent: 'Request.App.Content',
    RequestAppContentProducer: 'Request.App.Content.Producer',
    RequestDevice: 'Request.Device',
    RequestDeviceGeo: 'Request.Device.Geo',
    RequestUser: 'Request.User',
    RequestUserGeo: 'Request.User.Geo',
    RequestSource: 'Request.Source',
    RequestRegs: 'Request.Regs',
    RequestPmp: 'Request.Pmp',
    Impression: 'Impression',
    ImpressionBanner: 'Impression.Banner',
    ImpressionVideo: 'Impression.Video',
    ImpressionVideoBoxingAllowed: 'Impression.Video.BoxingAllowed',
    ImpressionAudio: 'Impression.Audio',
    ImpressionNative: 'Impression.Native',
    ImpressionPmp: 'Impression.Pmp'
};

export const StatusResolver = {
    Active: 'Active',
    Paused: 'Paused',
    Test: 'Test',
    Disabled: 'Disabled',
};

export const DeliverySchema = [
    {
        id: 'ADM',
        name: 'ADM',
    },
    {
        id: 'NURL',
        name: 'NURL',
    },
    {
        id: 'VAST_URL',
        name: 'VAST_URL',
    },
    {
        id: 'ADM_WITH_BURL',
        name: 'ADM_WITH_BURL',
    }
];

export const CurrencyResolver = {
    RUB: 'RUB',
    USD: 'USD',
    EUR: 'EUR',
    UZS: 'UZS',
}

export const PerActionResolver = {
    CPM: 'CPM',
    Revshare: 'Revshare',
}

export const IdResolveStrategyResolver = {
    NULL: 'NULL',
    EXTERNAL: 'EXTERNAL',
    PASSTHRU: 'PASSTHRU',
}

export const AndOrResolver = {
    AND: 'And',
    OR: 'Or',
}
