// Modules
import React, { Component } from 'react';
import buildPrismaProvider, { buildQuery } from 'ra-data-opencrud';
import { Admin, Resource } from 'react-admin';
// Containers
import { PublisherList, PublisherEdit, PublisherCreate } from './containers/publishers';
import { ReporterList, ReporterCreate, ReporterEdit } from './containers/reporters';
import { SuppliesEdit, SuppliesList, SuppliesCreate } from './containers/supplies';
import { VideoList, VideoEdit, VideoCreate } from './containers/video';
import { BannerCreate, BannerEdit, BannerList } from './containers/banners';
import { TargetingCreate, TargetingEdit, TargetingList } from './containers/targeting';
import { DealsCreate, DealsEdit, DealsList } from './containers/deals';
import { AdjustmentCreate, AdjustmentEdit, AdjustmentList } from './containers/adjustments';
import { DspsCreate, DspsEdit, DspsList } from './containers/dsps';
import { CategoriesCreate, CategoriesEdit, CategoriesList } from './containers/categories';
import { MimeTypesCreate,  MimeTypesEdit,  MimeTypesList } from './containers/mimeTypes';
import { BackendTagList, BackendTagCreate, BackendTagEdit } from './containers/backendTag';
import { SSPList, SSPCreate, SSPEdit } from './containers/ssp';
// Constant
export const publisher = 'Publisher';
export const reporter = 'Reporter';
export const supply = 'Supply';
export const video = 'Video';
export const banner = 'Banner';
export const deal = 'Deal';
export const targeting = 'Targeting';
export const adjustment = 'Adjustment';
export const dsps = 'DemandSidePlatform';
export const category = 'Category';
export const mimeType = 'Mimetype';
export const backendTag = 'BackendTag';
export const ssp = 'Ssp';

const enhanceBuildQuery = introspection => (fetchType, resource, params) => {
    // For Targeting ID Int!
    if (resource === targeting && fetchType === 'GET_ONE') {
        return buildQuery(introspection)(fetchType, resource, {...params, id: +params.id});
    }
    // if (resource === dsps && fetchType === 'UPDATE') {
    //     const newParams = {...params, data : {...params.data, ext:  JSON.parse(params.data.ext) } };
    //     return buildQuery(introspection)(fetchType, resource, newParams);
    // }

    return buildQuery(introspection)(fetchType, resource, params);
}

class App extends Component {
    constructor() {
        super();
        this.state = { dataProvider: null };
    }

    componentDidMount() {
        buildPrismaProvider({ clientOptions: { uri: process.env.REACT_APP_BACKEND_URL || 'http://localhost:4466/' } , buildQuery: enhanceBuildQuery})
            .then(dataProvider => this.setState({ dataProvider })).catch(console.log);
    }

    render() {
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return <div>Loading...</div>;
        }

        return (
            <Admin dataProvider={dataProvider} title="Exchange UI">
                <Resource
                    name={deal}
                    list={DealsList}
                    edit={DealsEdit}
                    create={DealsCreate}
                />
                <Resource
                    name={targeting}
                    list={TargetingList}
                    edit={TargetingEdit}
                    create={TargetingCreate}
                />
                <Resource
                    name={dsps}
                    list={DspsList}
                    edit={DspsEdit}
                    create={DspsCreate}
                    options={{ label: 'DSPs' }}
                />
                <Resource
                    name={adjustment}
                    list={AdjustmentList}
                    edit={AdjustmentEdit}
                    create={AdjustmentCreate}
                />
                <Resource
                    name={category}
                    list={CategoriesList}
                    edit={CategoriesEdit}
                    create={CategoriesCreate}
                />
                <Resource
                    name={mimeType}
                    list={MimeTypesList}
                    edit={MimeTypesEdit}
                    create={MimeTypesCreate}
                />
                <Resource
                    name={backendTag}
                    list={BackendTagList}
                    edit={BackendTagEdit}
                    create={BackendTagCreate}
                    options={{ label: 'BackendTags' }}
                />
                <Resource
                    name={ssp}
                    list={SSPList}
                    edit={SSPEdit}
                    create={SSPCreate}
                    options={{ label: 'SSPs' }}
                />
            </Admin>
        );
    }
}

export default App;