import React from 'react';
import { List, Edit, Create, SimpleForm, required } from 'react-admin';
import {
    TextField,
    ShowButton,
    Datagrid,
    TextInput,
} from 'react-admin';
// Constants
import { SearchFilter } from '../../components';
import { Pagination } from '../../components';

export const MimeTypesList = (props) => (
    <List {...props} filters={<SearchFilter />} pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ShowButton />
        </Datagrid>
    </List>
);

export const MimeTypesCreate = (props) => (
    <Create {...props}>
        <MimeTypesSimpleForm redirect="list"/>
    </Create>
);

export const MimeTypesEdit = (props) => (
    <Edit {...props}>
        <MimeTypesSimpleForm redirect="list"/>
    </Edit>
);

const MimeTypesSimpleForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
    </SimpleForm>
);

