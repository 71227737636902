// Modules
import React from 'react';
import {
    Filter,
    List,
    Datagrid,
    TextField,
    RadioButtonGroupInput,
    ChipField,
    ShowButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceField,
    UrlField,
    ReferenceInput,
    AutocompleteInput,
    CheckboxGroupInput,
    AutocompleteArrayInput,
    ReferenceArrayInput,
} from 'react-admin';
// Constants
import { publisher, category } from '../../App';
// Validation
import { required } from 'react-admin';
import { Pagination } from '../../components';

const choices = [
    { _id: 0, name: 'APP' },
    { _id: 1, name: 'WEBSITE' }
];
//filters={<SuppliesFilter />}
export const SuppliesList = (props) => (
    <List {...props} filters={<SuppliesFilter />} pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <UrlField source="defaultURL" label="URL" />
            <TextField source="type" />
            <ReferenceField
                source="publisher.id"
                reference={publisher}
                label="Publisher"
                sortable={false}
            >
                <ChipField source="name" />
            </ReferenceField>
            <ShowButton />
        </Datagrid>
    </List>
);

export const SuppliesEdit = (props) => (
    <Edit {...props}>
        <SuppliesForm redirect="list"/>
    </Edit>
);

export const SuppliesCreate = (props) => (
    <Create {...props}>
        <SuppliesForm redirect="list"/>
    </Create>
);

export const SuppliesFilter = (props) => (
    <Filter {...props}>
        <CheckboxGroupInput
            alwaysOn={true}
            source="type_in"
            choices={choices}
            optionText="name"
            optionValue="name"
            label={null}
        />
    </Filter>
);

export const SuppliesForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
        <RadioButtonGroupInput
            source="type"
            optionText="name"
            optionValue="name"
            options={{ style: { flexDirection: 'row' } }}
            choices={choices}
            validate={[required()]}
            defaultValue="APP"
        />
        <ReferenceInput
            label="Publisher"
            source="publisher.id"
            reference={publisher}
            filterToQuery={searchText => ({ name_contains: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <TextInput source="defaultURL" label="Default URL" validate={[required()]} />
        <ReferenceArrayInput
            allowEmpty={true}
            label="Categories"
            source="catsIds"
            reference={category}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            allowEmpty={true}
            label="Blocked Categories"
            source="blockedCatsIds"
            reference={category}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
    <TextInput source="longId" label="Long id" />
    </SimpleForm>
);