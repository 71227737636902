import React from 'react';
import { List, Edit, Create, SimpleForm, required } from 'react-admin';
import {
    TextField,
    ShowButton,
    Datagrid,
    ChipField,
    TextInput,
    SelectInput,
    ReferenceArrayInput,
    RadioButtonGroupInput,
    BooleanInput,
    AutocompleteArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    BooleanField,
    NumberInput,
} from 'react-admin';
import { JSONEditor } from 'ra-input-json';
// Constants
import { targeting, backendTag, dsps, adjustment } from '../../App';
import { StatusResolver, CurrencyResolver, DeliverySchema } from '../../constants';
import { Pagination } from '../../components';

export const SSPList = (props) => (
  <List {...props} title="SSP" pagination={<Pagination/>} perPage={50}>
      <Datagrid rowClick="edit">
          <TextField source="name" label="Name"/>
          <ChipField source="status" abel="Status"/>
          <ChipField source="delivery_schema" label="Creative Delivery Schema" />
          <ChipField source="currency" abel="Currency"/>
          <ReferenceArrayField source="target_dspsIds" reference={dsps} label="Target DSPs" sortable={false}>
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
          <BooleanField source="preserve_ids" label="Preserve Upstream IDs" />
          <ReferenceArrayField source="floor_adjustmentsIds" reference={adjustment} label="Floor Price Adjustments" sortable={false}>
              <SingleFieldList>
                  <ChipField source="name" />
              </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField source="tagsIds" reference={backendTag} label="SSP Flags" sortable={false}>
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField source="includeSSPIds" reference={targeting} label="Included Targetings" sortable={false}>
              <SingleFieldList>
                  <TextField source="name" />
              </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField source="excludeSSPIds" reference={targeting} label="Excluded Targetings" sortable={false}>
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField source="excludeBidsIds" reference={targeting} label="Bid Blocks" sortable={false}>
              <SingleFieldList>
                  <TextField source="name" />
              </SingleFieldList>
          </ReferenceArrayField>
          <ShowButton />
      </Datagrid>
  </List>
);

export const SSPCreate = (props) => (
  <Create {...props}>
      <SSPSimpleForm redirect="list" />
  </Create>
);

export const SSPEdit = (props) => (
  <Edit {...props}>
      <SSPSimpleForm redirect="list" />
  </Edit>
);

const SSPSimpleForm = (props) => (
  <SimpleForm {...props}>
      <TextInput source="name" />
      <RadioButtonGroupInput
          defaultValue={StatusResolver.Test}
          source="status"
          optionText="name"
          optionValue="name"
          options={{ style: { flexDirection: 'row' } }}
          choices={[
              { _id: 0, name: StatusResolver.Active },
              { _id: 1, name: StatusResolver.Paused },
              { _id: 1, name: StatusResolver.Test }
          ]}
      />
      <TextInput source="proxy_class" label="Proxy Class" defaultValue="OrtbProxy" validate={[required()]} />
      <SelectInput
          defaultValue={DeliverySchema[0].id}
          source="delivery_schema"
          label="Creative Delivery Schema"
          choices={DeliverySchema}
          validate={[required()]}
      />
      <SelectInput
          defaultValue={CurrencyResolver.RUB}
          label="Currency"
          source="currency"
          optionValue="value"
          optionText="value"
          choices={[
              { _id: 0, value: CurrencyResolver.RUB },
              { _id: 1, value: CurrencyResolver.USD },
              { _id: 2, value: CurrencyResolver.EUR },
              { _id: 3, value: CurrencyResolver.UZS },
          ]}
      />
      <ReferenceArrayInput
          allowEmpty={true}
          label="Target DSPs"
          source="target_dspsIds"
          reference={dsps}
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name_contains: searchText })}
      >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="preserve_ids" label="Preserve Upstream IDs" defaultValue={false} />
      <ReferenceArrayInput
          allowEmpty={true}
          label="Price Adjustments"
          source="floor_adjustmentsIds"
          reference={adjustment}
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name_contains: searchText })}
      >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="s2s_nurl" label="s2s Nurl Call" defaultValue={false} />
      <ReferenceArrayInput
          allowEmpty={true}
          label="SSP Flags"
          source="tagsIds"
          reference={backendTag}
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name_contains: searchText })}
      >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
          allowEmpty={true}
          label="Blocked Bids"
          source="excludeBidsIds"
          reference={targeting}
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name_contains: searchText })}
      >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
          allowEmpty={true}
          label="Included Targetings"
          source="includeSSPIds"
          reference={targeting}
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name_contains: searchText })}
      >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
          allowEmpty={true}
          label="Excluded Targetings"
          source="excludeSSPIds"
          reference={targeting}
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={searchText => ({ name_contains: searchText })}
      >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <JSONEditor source="ext" label="Json Extension" />
  </SimpleForm>
);
