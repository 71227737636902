// Modules
import React from 'react';
import { List, Edit, Create, SimpleForm, required } from 'react-admin';
import {
    TextField,
    Datagrid,
    TextInput,
} from 'react-admin';
import { SearchFilter } from '../../components';
// Constants
import { Pagination } from '../../components';

export const BackendTagList = props => (
  <List {...props}  filters={<SearchFilter />} title="BackendTag" pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

export const BackendTagCreate = props => (
  <Create {...props}>
      <BackendTagForm redirect="list"/>
  </Create>
);

export const BackendTagEdit = props => (
  <Edit {...props}>
      <BackendTagForm redirect="list"/>
  </Edit>
);

const BackendTagForm = props => (
  <SimpleForm {...props}>
      <TextInput source="name" validate={[required()]} />
  </SimpleForm>
);

