// Modules
import React from 'react';
import { List, Edit, Create, SimpleForm, Filter, required } from 'react-admin';
import {
    TextField,
    ShowButton,
    Datagrid,
    ChipField,
    UrlField,
    TextInput,
    SelectInput,
    CheckboxGroupInput,
    ReferenceArrayInput,
    RadioButtonGroupInput,
    BooleanInput,
    AutocompleteArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    NumberInput,
    BooleanField,
} from 'react-admin';
// Constants
import {targeting, backendTag, adjustment} from '../../App';
import { StatusResolver, CurrencyResolver, DeliverySchema, IdResolveStrategyResolver } from '../../constants';
import { JSONEditor } from 'ra-input-json';
import { Pagination } from '../../components';

export const DspsList = (props) => (
    <List {...props}  filters={<DspsFilters />} title="DSPs" pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ChipField source="status" />
            <ChipField source="delivery_schema" label="Delivery Schema" />
            <ChipField source="currency" label="Currency" />
            <TextField source="qps_limit" label="QPS Limit" />
            <BooleanField source="banner" label="Banner Traffic" />
            <BooleanField source="video" label="Video Traffic" />
            <ReferenceArrayField source="floor_adjustmentsIds" reference={adjustment} label="Floor Price Adjustments" sortable={false}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField source="tagsIds" reference={backendTag} sortable={false}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField source="includeIds" reference={targeting} label="Included Targetings" sortable={false}>
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>            
            <ReferenceArrayField source="excludeIds" reference={targeting} label="Excluded Targetings" sortable={false}>
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ShowButton />
        </Datagrid>
    </List>
);

export const DspsCreate = (props) => (
    <Create {...props}>
        <DspsSimpleForm redirect="list"/>
    </Create>
);

export const DspsEdit = (props) => (
    <Edit {...props}>
        <DspsSimpleForm redirect="list"/>
    </Edit>
);

const DspsSimpleForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
        <RadioButtonGroupInput
            defaultValue={StatusResolver.Active}
            source="status"
            optionText="name"
            optionValue="name"
            options={{ style: { flexDirection: 'row' } }}
            choices={[
                { _id: 0, name: StatusResolver.Active },
                { _id: 1, name: StatusResolver.Paused },
                { _id: 1, name: StatusResolver.Test }
            ]}
        />
        <TextInput source="proxy_class" label="Proxy Class" defaultValue="BaseDSPProxy" validate={[required()]} />
        <TextInput source="dmpId" label="DMP ID" />
        <TextInput source="url" label="URL" fullWidth validate={[required()]} />
        <BooleanInput source="banner" />
        <BooleanInput source="video" />
        <BooleanInput source="audio" />
        <SelectInput
            defaultValue={CurrencyResolver.RUB}
            label="Currency"
            source="currency"
            optionValue="value"
            optionText="value"
            choices={[
                { _id: 0, value: CurrencyResolver.RUB },
                { _id: 1, value: CurrencyResolver.USD },
                { _id: 2, value: CurrencyResolver.EUR },
                { _id: 3, value: CurrencyResolver.UZS },
            ]}
        />
        <ReferenceArrayInput
            allowEmpty={true}
            label="Price Adjustments"
            source="floor_adjustmentsIds"
            reference={adjustment}
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <NumberInput source="qps_limit" label="QPS Limit (0 means no limit)" />
        <NumberInput defaultValue={0.0} source="antifraud_score" label="Max Fraud score" helperText="above - the more likely the fraud" />
        <ReferenceArrayInput
            allowEmpty={true}
            label="Included Targetings"
            source="includeIds"
            reference={targeting}
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            allowEmpty={true}
            label="Excluded Targetings"
            source="excludeIds"
            reference={targeting}
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
            defaultValue={DeliverySchema[0].id}
            source="delivery_schema"
            label="Creative Delivery Schema"
            choices={DeliverySchema}
            validate={[required()]}
        />
        <SelectInput
            defaultValue={IdResolveStrategyResolver.EXTERNAL}
            source="id_resolve_strategy"
            label="ID Resolve Strategy"
            choices={[
                    {
                        id: IdResolveStrategyResolver.NULL,
                        name: IdResolveStrategyResolver.NULL,
                    },
                    {
                        id: IdResolveStrategyResolver.EXTERNAL,
                        name: IdResolveStrategyResolver.EXTERNAL,
                    },
                    {
                        id: IdResolveStrategyResolver.PASSTHRU,
                        name: IdResolveStrategyResolver.PASSTHRU,
                    }
            ]}
            validate={[required()]}
        />
        <ReferenceArrayInput
            allowEmpty={true}
            label="DSP Flags"
            source="tagsIds"
            reference={backendTag}
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <JSONEditor source="ext" label="Json Extension" />

    </SimpleForm>
);

const DspsFilters = (props) => (
    <Filter {...props}>
        <CheckboxGroupInput
            alwaysOn={true}
            source="status_in"
            choices={[
                { _id: 0, value: StatusResolver.Active },
                { _id: 1, value: StatusResolver.Paused },
                { _id: 2, value: StatusResolver.Test },
            ]}
            optionText="value"
            optionValue="value"
            label={null}
        />
    </Filter>
);
