// Modules
import React from 'react';
import { List, Edit, Create, SimpleForm, Filter, required } from 'react-admin';
import {
    TextField,
    ShowButton,
    Datagrid,
    TextInput,
    SelectInput,
    CheckboxGroupInput,
    ReferenceArrayInput,
    NumberInput,
    LongTextInput,
    SelectArrayInput,
    ReferenceArrayField,
    RadioButtonGroupInput,
    SingleFieldList,
    ReferenceField,
    ChipField,
    AutocompleteInput,
    AutocompleteArrayInput,
    ReferenceInput,
    BooleanInput,
} from 'react-admin';
// Constants
import { dsps, targeting } from '../../App';
import {CurrencyResolver, StatusResolver} from '../../constants';
import { Pagination } from '../../components';

export const DealsList = (props) => (
<List {...props} filters={<DealsFilters />} pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="longId" label="Long ID" />
            <TextField source="name" label="Name"/>
            <ReferenceArrayField source="sendToDspIds" reference={dsps} label="DSPs" sortable={false}>
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="dealFloor" label="Floor"/>
            <TextField source="status" label="Status"/>
            <TextField source="priority" label="Priority"/>
            <TextField source="actionType" label="Type" />
            <ReferenceField source="targetings.id" label="Targetings" reference={targeting} sortable={false}>
                    <ChipField source="name" />
            </ReferenceField>
            <ShowButton />
        </Datagrid>
    </List>
);
//sendToDsps
export const DealsCreate = (props) => (
    <Create {...props}>
        <DealsSimpleForm redirect="list" />
    </Create>
);

export const DealsEdit = (props) => (
    <Edit {...props}>
        <DealsSimpleForm redirect="list" />
    </Edit>
);

const DealsSimpleForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
        <RadioButtonGroupInput
            source="status"
            optionText="name"
            optionValue="name"
            options={{ style: { flexDirection: 'row' } }}
            choices={[
                { _id: 0, name: 'Active' },
                { _id: 1, name: 'Disabled' }
            ]}
        />
        <NumberInput defaultValue={0.0} source="antiFraudScore" label="Max Fraud score" helperText="above - the more likely the fraud" />
        <LongTextInput source="segmentTargeting" label="Segments" helperText="Supported DMP: weborama, beeline, aidata; format: weborama:1,2,3" />
        <ReferenceArrayInput
            source="sendToDspIds"
            allowEmpty={true}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={500}
            filterToQuery={ searchText => ({ name_contains: searchText })}
            reference={dsps}
            label="DSP">
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <TextInput source="longId" label="Long id" />
        <RadioButtonGroupInput
            source="dealFloor"
            optionValue="value"
            optionText="value"
            label="Deal floor"
            choices={[
                { _id: 0, value: 'Mediacost' },
                { _id: 1, value: 'Floor' },
                { _id: 2, value: 'FixedPrice' },
            ]}
        />
        <NumberInput source="fixedPrice" label="Fixed Deal floor" />
        <NumberInput defaultValue={0.0} source="maxFloor" label="Max Floor" helperText="filtered traffic by max floor" />
        <SelectInput
            defaultValue={CurrencyResolver.RUB}
            label="Currency"
            source="currency"
            optionValue="value"
            optionText="value"
            choices={[
                { _id: 0, value: CurrencyResolver.RUB },
                { _id: 1, value: CurrencyResolver.USD },
                { _id: 2, value: CurrencyResolver.EUR },
                { _id: 3, value: CurrencyResolver.UZS },
            ]}
        />
        <RadioButtonGroupInput
            source="actionType"
            optionValue="value"
            optionText="value"
            label="Auction type"
            choices={[
                { _id: 0, value: 'FixedPrice' },
                { _id: 1, value: 'FirstPrice' },
                { _id: 2, value: 'SecondPrice' },
            ]}
        />
        <ReferenceInput
            allowEmpty={true}
            label="Targeting"
            source="targetings.id"
            perPage={100}
            reference={targeting}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <BooleanInput label="Post Bid Targeting Evaluation" source="postBid"/>
        <SelectInput
            label="Priority"
            source="priority"
            optionValue="value"
            optionText="value"
            choices={[
                { _id: 0, value: -10 },
                { _id: 1, value: -9 },
                { _id: 2, value: -8 },
                { _id: 3, value: -7 },
                { _id: 4, value: -6 },
                { _id: 5, value: -5 },
                { _id: 6, value: -4 },
                { _id: 7, value: -3 },
                { _id: 8, value: -2 },
                { _id: 9, value: -1 },
                { _id: 10, value: 0 },
                { _id: 11, value: 1 },
                { _id: 12, value: 2 },
                { _id: 13, value: 3 },
                { _id: 14, value: 4 },
                { _id: 15, value: 5 },
                { _id: 16, value: 6 },
                { _id: 17, value: 7 },
                { _id: 18, value: 8 },
                { _id: 19, value: 9 },
                { _id: 20, value: 10 },
            ]}
        />
    </SimpleForm>
);

const DealsFilters = (props) => (
    <Filter {...props}>
        <CheckboxGroupInput
            alwaysOn={true}
            source="status_in"
            choices={[
                { _id: 0, value: StatusResolver.Active },
                { _id: 1, value: StatusResolver.Paused },
                { _id: 2, value: StatusResolver.Test },
            ]}
            optionText="value"
            optionValue="value"
            label={null}
        />
    </Filter>
);