// Modules
import React from 'react';
import {
    Create,
    List,
    Datagrid,
    TextField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    ShowButton,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    LongTextInput,
} from 'react-admin';
// Validation
import { required } from 'react-admin';
// Components
import { SearchFilter, LinkField } from '../../components';
// Constants
import { reporter } from '../../App';
import { Pagination } from '../../components';

export const PublisherList = (props) => (
    <List {...props} filters={<SearchFilter />} pagination={<Pagination/>} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <TextField source="companyName" label="Company Name" />
            <LinkField source="phone" label="Phone" prefix="tel:" />
            <ReferenceArrayField source="reportersIds" reference={reporter} sortable={false}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ShowButton />
        </Datagrid>
    </List>
);

export const PublisherEdit = (props) => (
    <Edit {...props}>
        <PublisherSimpleForm redirect="list"/>
    </Edit>
);

export const PublisherCreate = (props) => (
    <Create {...props}>
        <PublisherSimpleForm redirect="list"/>
    </Create>
);

const PublisherSimpleForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="companyName" />
        <TextInput source="phone" />
        <TextInput source="webmoney" />
        <TextInput source="longId" label="Long id" />
        <LongTextInput source="info" label="Additional info"/>
        <ReferenceArrayInput
            allowEmpty={true}
            label="Repporting access"
            source="reportersIds"
            reference={reporter}
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name_contains: searchText })}
        >
            <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
    </SimpleForm>
);
