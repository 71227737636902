// Modules
import React from 'react';
import { List, Edit, Create, SimpleForm, required } from 'react-admin';
import {
    TextField,
    ShowButton,
    Datagrid,
    TextInput,
    SelectInput,
    NumberInput,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    ChipField,
} from 'react-admin';
// Constants
import { targeting } from '../../App';
import { Pagination } from '../../components';

export const AdjustmentList = (props) => (
    <List {...props} pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <TextField source="adjustment" />
            <ShowButton />
        </Datagrid>
    </List>
);

export const AdjustmentCreate = (props) => (
    <Create {...props}>
        <AdjustmentSimpleForm redirect="list" />
    </Create>
);

export const AdjustmentEdit = (props) => (
    <Edit {...props}>
        <AdjustmentSimpleForm redirect="list" />
    </Edit>
);

const AdjustmentSimpleForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
        <NumberInput source="adjustment" validate={[required()]} />
    </SimpleForm>
);