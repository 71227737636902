// Modules
import React from 'react';
import { Filter, TextInput } from 'react-admin';

export function SearchFilter(props) {
    const { alwaysOn, ...rest } = props;
    return (
        <Filter {...rest}>
            <TextInput
                label={rest.label}
                source={rest.source}
                alwaysOn={alwaysOn}
            />
        </Filter>
    );
}

SearchFilter.displayName = 'SearchFilter';

SearchFilter.defaultProps = {
    alwaysOn: true,
    source: 'name_contains',
    label: 'Search',
};
