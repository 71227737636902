// Modules
import React from 'react';
import {
    Create,
    List,
    Datagrid,
    TextField,
    EmailField,
    ShowButton,
    Edit,
    SimpleForm,
    TextInput,
    LongTextInput,
    BooleanInput,
} from 'react-admin';
// Validation
import { required, email } from 'react-admin';
// Components
import { LinkField, SearchFilter } from '../../components';
import { Pagination } from '../../components';

export const ReporterList = (props) => (
    <List {...props} filters={<SearchFilter />} pagination={<Pagination/>} perPage={50}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" label="Name" />
            <TextField source="lastName" label="Last Name" />
            <LinkField source="phone" label="Phone" prefix="tel:" />
            <EmailField source="email" label="email" />
            <LinkField source="skype" label="skype" prefix="skype:" postfix="?call" />
            <ShowButton />
        </Datagrid>
    </List>
);

const ReporterSimpleForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="lastName" />
        <TextInput source="phone" />
        <TextInput source="email" validate={[email()]} />
        <TextInput source="skype" />
        <LongTextInput source="info" label="Additional info"/>
        <TextInput source="token" />
        <TextInput source="password" />
        <BooleanInput source="isAdmin" label="Is Admin" />
    </SimpleForm>
);

export const ReporterEdit = (props) => (
    <Edit {...props}>
        <ReporterSimpleForm redirect="list" />
    </Edit>
);

export const ReporterCreate = (props) => (
    <Create {...props}>
        <ReporterSimpleForm redirect="list" />
    </Create>
);
